.product-info {
    padding: 2% 4%;
}

.product-info .top, .product-info .more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 82px auto;
}

.product-info .top .left, .product-info .more .left {
    flex: 1;
    padding-right: 20px;
}

.product-info .top .right, .product-info .more .right {
    flex: 1;
    text-align: center;
}

.product-info .top .left h1, .product-info .more .left h1 {
    font-weight: 600;
    font-size: 32px;
    color: var(--black);
}

.product-info .top .left p, .product-info .more .left p, .product-info .more .left ol {
    font-weight: 400;
    font-size: 18px;
    color: var(--grey);
    margin: 20px 0;
}

.product-contact-link, .product-info a {
    width: 228px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: var(--white);
    background: var(--black);
    border-radius: 35px;
    transition: 0.3s all;
    border: 2px solid var(--black);
    margin-top: 16px;
}

.product-contact-link:hover, .product-info a:hover {
    border: 2px solid var(--purple);
    color: var(--purple);
    background: transparent;
}

.product-info .top .right img, .product-info .more .right img {
    width: 100%;
    max-width: 400px;
    height: auto;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .product-info .top, .product-info .more {
        flex-direction: column;
    }

    .product-info .top .left, .product-info .more .left,
    .product-info .top .right, .product-info .more .right {
        width: 100%;
        padding-right: 0;
    }

    .product-info .top .left h1, .product-info .more .left h1 {
        font-size: 28px;
    }

    .product-info .top .left p, .product-info .more .left p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .product-info {
        padding: 2% 2%;
    }

    .product-info .top .left h1, .product-info .more .left h1 {
        font-size: 24px;
    }

    .product-info .top .left p, .product-info .more .left p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .product-info .top .left h1, .product-info .more .left h1 {
        font-size: 20px;
    }

    .product-info .top .left p, .product-info .more .left p {
        font-size: 12px;
    }

    .product-contact-link, .product-info a {
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
}
@font-face {
  font-family: "San Francisco";
  src: url("./assets/fonts/SFUIDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  font-family: "San Francisco";
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0 auto;
  position: relative;
  max-width: 1800px;
  width: 100%;
}

:root {
  --black: #000;
  --white: #fff;
  --grey: #737d8c;
  --purple: #881fff;
  --light_purple: #eeeffc;
  --light_blue: #D4EDFF;
  --light_white: #F9F9F9;
  --dark_bg: #1f2225;
  --green: #1C8731;
}

a {
  text-decoration: none;
}

/* ul {
  list-style: none;
} */

.fill-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s all;
}

.active {
  display: block;
}

.inline {
  display: flex;
  align-items: center;
  justify-content: start;
}

.open-lg {
  visibility: visible !important;
  opacity: 1 !important;
}